import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.table`
  width: 100%;
  margin: 20px 0 40px;

  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.paleCornflowerBlueLight};
  }

  thead {
    background: ${({ theme }) => theme.colors.paleCornflowerBlue};
    color: ${({ theme }) => theme.colors.white};
    font-size: 20px;
    font-wieght: normal;

    th {
      padding: 20px;
      font-wieght: 400;

      :first-child {
        width: 80%;
        align-items: left;
        text-transform: uppercase;
        letter-spacing: 0.04em;
      }

      :last-child {
        width: 20%;
        align-items: right;
        font-size: 14px;
      }
    }
  }

  tbody {
    font-size: 18px;

    tr {
      td {
        padding: 10px 20px;

        span {
          display: block;
        }

        :last-child {
          text-align: center;
        }
      }
    }
  }
`

const Table = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

export default Table
