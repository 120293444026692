import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { graphql } from 'gatsby'

import Layout from '../Layout'
import SEO from '../components/SEO'

import AsideImage from '../components/AsideImage/AsideImage'
import Button from '../components/Button/Button'
import Table from '../components/Table/Table'

import VisaIcon from '../assets/svg/payments/visa-color.svg'
import MasterCardIcon from '../assets/svg/payments/mastercard-color.svg'
import MaestroIcon from '../assets/svg/payments/maestro-color.svg'

const Icons = styled.p`
  opacity: 1 !important;
`

const StyledCardIcon = styled(SVG)`
  position: relative;
  top: -15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 37px;
  margin-right: 5px;
  svg {
    width: 100%;
    height: 100%;
  }
`

const UslugiPage = ({ data }) => (
  <Layout
    header="Cennik"
    asideContent={[
      <AsideImage
        key="asideImage"
        srcImage={data.coverPhoto.childImageSharp.fluid}
      >
        <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
          Umów wizytę
        </Button>
      </AsideImage>,
    ]}
  >
    <SEO title="Cennik | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <p>
      Poniższy cennik nie zawiera wszystkich usług i zabiegów oferowanych w
      naszym gabinecie i jest prezentowany wyłącznie orientacyjnie. Ostateczne
      ceny mogą nieznacznie różnić się od podanych, w zależności od stopnia
      trudności zabiegu oraz rodzaju użytych materiałów.
    </p>
    <p>Honorujemy płatności kartą</p>
    <Icons>
      <StyledCardIcon src={VisaIcon} />
      <StyledCardIcon src={MasterCardIcon} />
      <StyledCardIcon src={MaestroIcon} />
    </Icons>

{/* Stomatologia zachowawcza i dziecięca */}
<Table>
  <thead>
    <tr>
      <th>Stomatologia zachowawcza i dziecięca</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Konsultacja stomatologiczna</td>
      <td>bezpłatna</td>
    </tr>
    <tr>
      <td>Przegląd okresowy (dorośli i dzieci)</td>
      <td>50 - 100</td>
    </tr>
    <tr>
      <td>Znieczulenie</td>
      <td>w cenie zabiegu</td>
    </tr>
    <tr>
      <td>Wypełnienie kompozytowe zęba stałego</td>
      <td>250 - 280</td>
    </tr>
    <tr>
      <td>Wypełnienie kompozytowe zęba mlecznego</td>
      <td>150 - 200</td>
    </tr>
    <tr>
      <td>Bezinwazyjne leczenie wczesnej próchnicy</td>
      <td>od 200</td>
    </tr>
    <tr>
      <td>Odbudowa korony zębów metodą bezpośrednią</td>
      <td>400</td>
    </tr>
    <tr>
      <td>Opatrunek (fleczer)</td>
      <td>150</td>
    </tr>
  </tbody>
</Table>

{/* Leczenie kanałowe */}
<Table>
  <thead>
    <tr>
      <th>Leczenie kanałowe</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Ząb 1 kanałowy</td>
      <td>350</td>
    </tr>
    <tr>
      <td>Ząb 2 kanałowy</td>
      <td>350</td>
    </tr>
    <tr>
      <td>Ząb 3 kanałowy</td>
      <td>od 350</td>
    </tr>
  </tbody>
</Table>

  {/* Profilaktyka stomatologiczna */}
<Table>
  <thead>
    <tr>
      <th>Profilaktyka i wybielanie</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        Pełna higienizacja stomatologiczna <br />
        (skaling, piaskowanie, fluoryzacja, polerowanie, instruktaż higieny)
      </td>
      <td>360</td>
    </tr>
    <tr>
      <td>Fluoryzacja całej jamy ustnej</td>
      <td>280</td>
    </tr>
    <tr>
      <td>Skaling (2 łuki zębowe)</td>
      <td>280</td>
    </tr>
    <tr>
      <td>Piaskowanie (2 łuki zębowe)</td>
      <td>200</td>
    </tr>
    <tr>
      <td>Zabezpieczenie profilaktyczne bruzd lakiem szczelinowym <br />
      (cena za każdy ząb)</td>
      <td>200</td>
    </tr>
    <tr>
      <td>Wybielanie nakładkowe</td>
      <td>750</td>
    </tr>
    <tr>
      <td>Wybielanie lampą</td>
      <td>1500</td>
    </tr>
    <tr>
      <td>Biżuteria nazębna - Swarovski (kryształek lub grillz)</td>
      <td>250 - 350</td>
    </tr>
  </tbody>
</Table>

  {/* Chirurgia stomatologiczna */}
<Table>
  <thead>
    <tr>
      <th>Chirurgia stomatologiczna</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Ekstrakcja ósemek</td>
      <td>500 - 800</td>
    </tr>
    <tr>
      <td>Ekstrakcja zęba jednokorzeniowego</td>
      <td>200 - 400</td>
    </tr>
    <tr>
      <td>Ekstrakcja zęba 2-korzeniowego</td>
      <td>300 - 400</td>
    </tr>
    <tr>
      <td>Ekstrakcja zęba 3-korzeniowego</td>
      <td>400 - 600</td>
    </tr>
    <tr>
      <td>Resekcje wierzchołków korzeni</td>
      <td>1000</td>
    </tr>
    <tr>
      <td>Sinus lift zatoki i odbudowa kości</td>
      <td>2500 - 3500</td>
    </tr>
    <tr>
      <td>PRF - osocze bogatopłytkowe</td>
      <td>1000</td>
    </tr>
    <tr>
      <td>Nacięcie ropnia</td>
      <td>250 - 350</td>
    </tr>
    <tr>
      <td>Konsultacja chirurgiczna</td>
      <td>150</td>
    </tr>
    <tr>
      <td>Usunięcie korzenia</td>
      <td>300</td>
    </tr>
    <tr>
      <td>Zdjęcie szwów</td>
      <td>w cenie zabiegu</td>
    </tr>
  </tbody>
</Table>

{/* Ortodoncja */}
<Table>
  <thead>
    <tr>
      <th>Ortodoncja</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Konsultacja ortodontyczna</td>
      <td>bezpłatna</td>
    </tr>
    <tr>
      <td>Opracowanie planu leczenia metodą Clear Aligner (skan, analiza, kosztorys)</td>
      <td>250</td>
    </tr>
    <tr>
      <td>Aparat estetyczny Clear Aligner (cena za 1 łuk)</td>
      <td>800</td>
    </tr>
    <tr>
      <td>Retainer estetyczny</td>
      <td>450</td>
    </tr>
    <tr>
      <td>Retainer stały</td>
      <td>500</td>
    </tr>
  </tbody>
</Table>

  {/* Periodontologia */}
<Table>
  <thead>
    <tr>
      <th>Periodontologia</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Kiretaż</td>
      <td>od 90</td>
    </tr>
  </tbody>
</Table>

{/* Implantologia */}
<Table>
  <thead>
    <tr>
      <th>Implantologia</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Konsultacja implantologiczna</td>
      <td>150</td>
    </tr>
    <tr>
      <td>Wszczepienie implantu (Straumann)</td>
      <td>2900</td>
    </tr>
    <tr>
      <td>Odsłonięcie implantu</td>
      <td>400</td>
    </tr>
    <tr>
      <td>Korona cyrkonowa na implancie z łącznikiem</td>
      <td>3300</td>
    </tr>
    <tr>
      <td>Proteza na 4 implantach</td>
      <td>ok. 20 000</td>
    </tr>
    <tr>
      <td>Proteza na 3 implantach</td>
      <td>ok. 17 000</td>
    </tr>
  </tbody>
</Table>

  {/* Protetyka */}
<Table>
  <thead>
    <tr>
      <th>Protetyka</th>
      <th>cena w PLN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Wkład koronowo - korzeniowy</td>
      <td>450</td>
    </tr>
    <tr>
      <td>Korona tymczasowa</td>
      <td>130 - 490</td>
    </tr>
    <tr>
      <td>Korona porcelanowa na metalu</td>
      <td>1100</td>
    </tr>
    <tr>
      <td>Korona pełnoceramiczna Procera/Everest</td>
      <td>1500</td>
    </tr>
    <tr>
      <td>Korona pełnokonturowa</td>
      <td>1500</td>
    </tr>
    <tr>
      <td>Licówka kompozytowa</td>
      <td>1200</td>
    </tr>
    <tr>
      <td>Licówka porcelanowa</td>
      <td>1400</td>
    </tr>
    <tr>
      <td>Bonding (cena za 1 ząb)</td>
      <td>600</td>
    </tr>
    <tr>
      <td>Proteza akrylowa całkowita</td>
      <td>1100</td>
    </tr>
    <tr>
      <td>Proteza szkieletowa</td>
      <td>2000</td>
    </tr>
    <tr>
      <td>Proteza elastyczna</td>
      <td>1800</td>
    </tr>
    <tr>
      <td>Proteza drukowana</td>
      <td>2000</td>
    </tr>
  </tbody>
</Table>
  </Layout>
)

export default UslugiPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
